<template>
  <label
    :for="id"
    class="upload-file"
    :class="{'is-loading': loading, ['upload-file--' + size]: size}"
  >
    <div class="upload-file__preview">
      <div
        v-if="file.url"
        class="upload-file-cover"
        @click.prevent="deleteImage"
      >
        <div class="upload-file-cover__image">
          <img
            :src="file.url"
            :alt="file.filename"
          >
          <div class="upload-file-cover__icon">
            <feather-icon
              icon="Trash2Icon"
              :size="iconSize"
            />
          </div>
        </div>
      </div>
      <feather-icon
        v-else
        icon="ImageIcon"
        :size="iconSize"
      />
    </div>

    <div class="upload-file__content">
      <span v-if="file.filename">{{ file.filename }}</span><br>
      <span v-if="file.size_human_readable">{{ file.size_human_readable }}</span>
    </div>
    <input
      :id="id"
      ref="file"
      type="file"
      :name="id"
      @input="uploadFile"
    >
  </label>
</template>

<script>
/* eslint no-underscore-dangle: 0 */

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import File from '@/api/http/models/file/File'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'md', // 'sm' | 'md' | 'lg'
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  $_veeValidate: {
    value() {
      return this.value
    },
  },
  data() {
    return {
      id: null,
      fileKey: this.value,
      file: {},
    }
  },
  computed: {
    iconSize() {
      let result = null
      switch (this.size) {
        case 'sm':
          result = '16'
          break
        case 'md':
          result = '21'
          break
        case 'lg':
          result = '24'
          break
        default:
          result = '21'
          break
      }
      return result
    },
  },
  watch: {
    async value() {
      this.fileKey = this.value
      if (this.fileKey) {
        const response = await File.getOne(this.fileKey)
        if (response.data) this.file = response.data
      }
    },
  },
  mounted() {
    this.id = `file-input-${this._uid}`
  },
  async created() {
    if (this.fileKey) {
      const response = await File.getOne(this.fileKey)
      if (response.data) this.file = response.data
    }
  },
  methods: {
    async uploadFile() {
      const file = this.$refs.file.files[0]
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2)

      if (sizeInMB < 3) {
        const formData = new FormData()
        formData.append('file', file)
        const response = await File.upload(formData)
        if (response.status === 'success') {
          this.file = response.data
          this.fileKey = this.file.key
          this.$emit('input', this.fileKey)
          this.$emit('uploaded', this.file)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Файл загружен на сервер',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Произошла ошибка при загрузке файла',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Размер файла превышает 3MB',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    deleteImage() {
      this.file = {}
      this.$emit('input', null)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/base/bootstrap-extended/variables';

  .upload-file {
    display: flex;
    cursor: pointer;

    &__preview {
      background: $light;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      color: $primary;
      overflow: hidden;
      transition: .3s ease-in-out;
    }

    &:hover {
      .upload-file__preview {
        background: $primary;
        color: #fff;
      }
    }

    &--sm {
      .upload-file__preview {
        width: 60px;
        height: 60px;
      }
    }

    &--md {
      .upload-file__preview {
        width: 100px;
        height: 100px;
      }
    }

    &--lg {
      .upload-file__preview {
        width: 200px;
        height: 200px;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    &.is-loading {
      pointer-events: none;
      opacity: .8;

      .upload-file__preview {
        position: relative;

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 1.6rem;
          height: 1.6rem;
          margin: -0.8rem 0 0 -0.8rem;
          border: 0.3rem solid #7367f0;
          border-top-color: #f6f6f6;
          border-radius: 50%;
          animation: spin 2s linear infinite;
          content: '';
        }

        svg, .upload-file-cover {
          display: none;
        }
      }
    }
  }
  .upload-file-cover {
    position: relative;
    width: 100%;
    height: 100%;
    background: $light;

    &__image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: $red;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, .5);
      opacity: 0;
      transition: .2s ease-in-out;
    }

    &:hover {
      .upload-file-cover__icon {
        opacity: 1;
      }
    }
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
